<template>
  <div class="main">
    <Top />
    <div class="nav">
      <div class="nav_left">
        <div class="left_jian" @click="ret_plan">
          <img src="~@/assets/img/left_2.png" alt="" />
        </div>
        <div class="word">{{ lang.v.daily }}</div>
      </div>
      <div class="num_task">{{ lang.v.remaining }} {{ userinfo.taskNum }}</div>
    </div>
    <div style="background-color: #f6f6f8">
      <div class="tasks" v-for="(list, index) in filteredItems" :key="index">
        <div
          :class="[
            list.taskStatus == 4 ? 'tasks_top1' : '',
            list.taskStatus == 2 ? 'tasks_top2' : '',
            list.taskStatus == 3 ? 'tasks_top3' : '',
            list.taskStatus == 5 ? 'tasks_top4' : '',
            list.taskStatus == 1 ? 'tasks_top5' : '',
          ]"
        ></div>
        <div class="tasks_title">
          <div class="tasks_left">
            <div
              :class="[
                list.taskStatus == 4 ? 'task_logo1' : '',
                list.taskStatus == 2 ? 'task_logo2' : '',
                list.taskStatus == 3 ? 'task_logo3' : '',
                list.taskStatus == 5 ? 'task_logo4' : '',
                list.taskStatus == 1 ? 'task_logo5' : '',
              ]"
            ></div>
            <div>
              <div
                class="tasks_name"
                :class="[
                  list.taskStatus == 4 ? 'tasks_name1' : '',
                  list.taskStatus == 2 ? 'tasks_name2' : '',
                  list.taskStatus == 3 ? 'tasks_name3' : '',
                  list.taskStatus == 5 ? 'tasks_name4' : '',
                  list.taskStatus == 1 ? 'tasks_name5' : '',
                ]"
                :innerHTML="list.taskId == 1 ? list.taskContent : list.taskName"
              ></div>
              <div
                :class="list.taskStatus == 5 ? 'tasks_time1' : 'tasks_time'"
                v-if="list.taskStatus == 4 ? true : false"
              >
                {{ lang.v.Claim }} {{ list.taskTime }}
              </div>
              <div
                :class="list.taskStatus == 5 ? 'tasks_time1' : 'tasks_time'"
                v-if="
                  list.taskStatus == 4 || list.taskStatus == 5 ? false : true
                "
              >
                {{ lang.v.Submission }} {{ list.taskTime }}
              </div>
              <div
                :class="list.taskStatus == 5 ? 'tasks_time1' : 'tasks_time'"
                v-if="list.taskStatus == 5 ? true : false"
              >
                {{ lang.v.Completion }} {{ formattedDate }}
              </div>
            </div>
          </div>
          <div class="tasks_right">
            <div
              :class="[
                list.taskStatus == 4 ? 'receive1' : '',
                list.taskStatus == 2 ? 'receive2' : '',
                list.taskStatus == 3 ? 'receive3' : '',
                list.taskStatus == 5 ? 'receive4' : '',
                list.taskStatus == 1 ? 'receive5' : '',
              ]"
              :innerText="list.taskStatus == 5 ? completed : Complete"
            ></div>
          </div>
        </div>
        <transition name="slide">
          <div
            :class="{ hidden: isVisible(index) }"
            style="padding-bottom: 0.12rem"
            v-if="list.taskId == 1 ? false : true"
          >
            <div class="tishi_info" v-if="list.taskStatus == 1 ? true : false">
              <div class="tishi_logo">
                <img src="~@/assets/img/tishi_false.png" alt="" />
              </div>
              <div class="tishi_text" style="color: #dd4747">
                {{ lang.v.error }}
              </div>
            </div>
            <div class="tishi_info">
              <div class="tishi_logo">
                <img src="~@/assets/img/tishi_success.png" alt="" />
              </div>
              <div class="tishi_text">{{ lang.v.warning3 }}</div>
            </div>
            <div :class="list.taskStatus == 5 ? 'contant1' : 'contant'">
              <div>{{ list.taskContent }}</div>
              <div class="exambal">{{ lang.v.sample }}</div>
              <div style="display: flex; flex-wrap: wrap; margin-left: -0.1rem">
                <div
                  class="exam_img"
                  v-for="imgs in list.taskImageList"
                  :key="imgs"
                >
                  <div class="image-wrapper">
                    <div class="image">
                      <img :src="'https://oss.mnckj.com/' + imgs" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="exambal" style="padding-bottom: 0.1rem">
                {{ lang.v.Copywriting }}
              </div>
              <div>
                {{ list.sourceContent
                }}<img
                  class="copy_button"
                  @click="copyText(index)"
                  src="~@/assets/img/sucai.png"
                  alt=""
                />
              </div>
              <div style="display: flex; flex-wrap: wrap; margin-left: -0.1rem">
                <div
                  class="exam_img"
                  v-for="imgs in list.sourceImageList"
                  :key="imgs"
                >
                  <div class="image-wrapper">
                    <div class="image">
                      <img :src="'https://oss.mnckj.com/' + imgs" alt="" />
                    </div>
                  </div>
                  <div
                    class="box"
                    @click="downloadImage('https://oss.mnckj.com/' + imgs)"
                  >
                    <div class="load_img"></div>
                    <div class="down_load">{{ lang.v.download }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <div
          v-if="list.taskId == 1 ? false : true"
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            height: 0.3rem;
            border-top: 0.01rem solid rgba(133, 140, 159, 0.2);
          "
        >
          <div class="wenzi">{{ wenzi }}</div>
          <div class="scale" @click="Scale(index)">
            <img
              src="~@/assets/img/up.png"
              :class="{ rotated: isRotated(index) }"
              @click="toggleRotation(index)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="tasks" v-for="(list, index) in lists" :key="index">
        <div
          :class="[
            list.taskStatus == 4 ? 'tasks_top1' : '',
            list.taskStatus == 2 ? 'tasks_top2' : '',
            list.taskStatus == 3 ? 'tasks_top3' : '',
            list.taskStatus == 5 ? 'tasks_top4' : '',
            list.taskStatus == 1 ? 'tasks_top5' : '',
          ]"
        ></div>
        <div class="tasks_title">
          <div class="tasks_left">
            <div
              :class="[
                list.taskStatus == 4 ? 'task_logo1' : '',
                list.taskStatus == 2 ? 'task_logo2' : '',
                list.taskStatus == 3 ? 'task_logo3' : '',
                list.taskStatus == 5 ? 'task_logo4' : '',
                list.taskStatus == 1 ? 'task_logo5' : '',
              ]"
            ></div>
            <div>
              <div
                class="tasks_name"
                :class="[
                  list.taskStatus == 4 ? 'tasks_name1' : '',
                  list.taskStatus == 2 ? 'tasks_name2' : '',
                  list.taskStatus == 3 ? 'tasks_name3' : '',
                  list.taskStatus == 5 ? 'tasks_name4' : '',
                  list.taskStatus == 1 ? 'tasks_name5' : '',
                ]"
                :innerHTML="list.taskId == 1 ? list.taskContent : list.taskName"
              ></div>
              <div
                :class="list.taskStatus == 5 ? 'tasks_time1' : 'tasks_time'"
                v-if="list.taskStatus == 4 ? true : false"
              >
                {{ lang.v.Claim }} {{ list.taskTime }}
              </div>
              <div
                :class="list.taskStatus == 5 ? 'tasks_time1' : 'tasks_time'"
                v-if="
                  list.taskStatus == 4 || list.taskStatus == 5 ? false : true
                "
              >
                {{ lang.v.Submission }} {{ list.taskTime }}
              </div>
              <div
                :class="list.taskStatus == 5 ? 'tasks_time1' : 'tasks_time'"
                v-if="list.taskStatus == 5 ? true : false"
              >
                {{ lang.v.Completion }} {{ formattedDate }}
              </div>
            </div>
          </div>
          <div class="tasks_right">
            <div
              @click="tasks(list)"
              :class="[
                list.taskStatus == 4 ? 'receive1' : '',
                list.taskStatus == 2 ? 'receive2' : '',
                list.taskStatus == 3 ? 'receive3' : '',
                list.taskStatus == 5 ? 'receive4' : '',
                list.taskStatus == 1 ? 'receive5' : '',
              ]"
              :innerText="[
                list.taskStatus == 4
                  ? Claim2
                  : list.taskStatus == 2
                    ? Submit
                    : list.taskStatus == 3
                      ? Waiting
                      : list.taskStatus == 5
                        ? completed
                        : list.taskStatus == 1
                          ? Resubmit
                          : '',
              ]"
            ></div>
          </div>
        </div>
        <transition name="slide">
          <div
            :class="{ hidden: isVisible(index) }"
            style="padding-bottom: 0.12rem"
            v-if="list.taskId == 1 ? false : true"
          >
            <div class="tishi_info" v-if="list.taskStatus == 1 ? true : false">
              <div class="tishi_logo">
                <img src="~@/assets/img/tishi_false.png" alt="" />
              </div>
              <div class="tishi_text" style="color: #dd4747">
                {{ list.reason }} {{ lang.v.resubmit }}
              </div>
            </div>
            <div class="tishi_info">
              <div class="tishi_logo">
                <img src="~@/assets/img/tishi_success_hui.png" alt="" />
              </div>
              <div class="tishi_text">{{ lang.v.warning3 }}</div>
            </div>
            <div :class="list.taskStatus == 5 ? 'contant1' : 'contant'">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  padding-bottom: 0.06rem;
                  padding-top: 0.06rem;
                  border-bottom: 0.01rem solid rgba(133, 140, 159, 0.2);
                "
                v-for="(item, index_neirong) in list.taskContent"
                :key="index_neirong"
              >
                <div style="width: 85%; word-break: break-all">{{ item }}</div>
              </div>
              <div class="exambal" style="padding-bottom: 0.1rem">
                {{ lang.v.Copywriting }}
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  padding-bottom: 0.06rem;
                  padding-top: 0.06rem;
                  border-bottom: 0.01rem solid rgba(133, 140, 159, 0.2);
                "
                v-for="(item, index_sucai) in list.sourceContent"
                :key="index_sucai"
              >
                <div style="width: 85%; word-break: break-all">{{ item }}</div>
                <div class="copyimg">
                  <img
                    class="copy_button"
                    @click="copyText(index, index_sucai)"
                    src="~@/assets/img/sucai.png"
                    alt=""
                  />
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; margin-left: -0.1rem">
                <div
                  class="exam_img"
                  v-for="(imgs, imgIndex) in list.sourceImageList"
                  :key="imgIndex"
                >
                  <div class="image-wrapper">
                    <div
                      class="image"
                      @click.stop="toggleZoom(list.sourceImageList, imgIndex)"
                    >
                      <img :src="'https://oss.mnckj.com/' + imgs" alt="" />
                    </div>
                  </div>
                  <!-- <div class="box" @click="downloadImage('https://oss.mnckj.com/' + imgs)">
                                    <div class="load_img"></div>
                                    <div class="down_load">下载</div>
                                </div> -->
                </div>
                <van-image-preview
                  v-model:show="isshow"
                  :images="currentImages"
                  :start-position="currentIndex"
                  :swipeDuration="time_swipe"
                  @change="onChange"
                >
                </van-image-preview>
              </div>
              <div class="exambal">{{ lang.v.sample }}</div>
              <div style="display: flex; flex-wrap: wrap; margin-left: -0.1rem">
                <div
                  class="exam_img"
                  v-for="(imgs, imgIndex) in list.taskImageList"
                  :key="imgIndex"
                >
                  <div class="image-wrapper">
                    <div
                      class="image"
                      @click.stop="toggleZoom(list.taskImageList, imgIndex)"
                    >
                      <img :src="'https://oss.mnckj.com/' + imgs" alt="" />
                    </div>
                  </div>
                </div>
                <van-image-preview
                  v-model:show="isshow"
                  :images="currentImages"
                  :start-position="currentIndex"
                  :swipeDuration="time_swipe"
                  @change="onChange"
                >
                  <template v-slot:index>{{ currentIndex + 1 }}page</template>
                </van-image-preview>
              </div>
            </div>
          </div>
        </transition>
        <div
          v-if="list.taskId == 1 ? false : true"
          @click="Scale(index)"
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            height: 0.3rem;
            border-top: 0.01rem solid rgba(133, 140, 159, 0.2);
          "
        >
          <div class="wenzi">{{ isVisible(index) ? Expand : Recover }}</div>
          <div class="scale">
            <img
              src="~@/assets/img/up.png"
              :class="{ rotated: isRotated(index) }"
              @click="toggleRotation(index)"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block_top">
            <div class="block_top_left">
              <div class="bolck_name">{{ admition.taskName }}</div>
              <div class="over_time">
                {{ lang.v.Deadline }} {{ admition.taskTime }}
              </div>
            </div>
            <div class="block_top_right" @click="quxiao">
              <img src="~@/assets/img/hidden.png" alt="" />
            </div>
          </div>
          <div class="block_main">
            <van-uploader
              ref="uploader"
              :max-count="1"
              style="margin-left: 0.14rem"
              v-model="fileList"
              multiple
              :after-read="handleFileUpload"
              @delete="handleDeleteImage"
            />
          </div>
          <div class="block_foot">
            <div class="block_foot_left" v-if="admition == null ? false : true">
              <div class="bolck_name">{{ lang.v.text9 }}</div>
              <div class="over_time">{{ lang.v.text10 }}</div>
            </div>
            <div class="admit" @click="admit_task">{{ lang.v.Submit }}</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="show_moceng">
      <div class="wrapper"><van-loading type="spinner" size="0.50rem" /></div>
    </van-overlay>
    <Tabbar />
  </div>
</template>
<script setup>
import { onMounted, computed, ref } from 'vue';
import router from '@/router';
import OSS from 'ali-oss';
import { task, act_task, file_sts, admit_tasks } from '@/api/fetcher';
import message from '@/utils/message';
import { lang } from '@/lang';
import Top from '@/components/Top/top.vue';
import Tabbar from '@/components/Tabbar/tabbar.vue';
import Clipboard from 'clipboard';
const completed = lang.v.Completed;
const Claim2 = lang.v.Claim2;
const Submit = lang.v.Submit;
const Waiting = lang.v.Waiting;
const Complete = lang.v.Complete;
const Resubmit = lang.v.Resubmit;
const Expand = lang.v.Expand;
const Recover = lang.v.Recover;
const time_swipe = ref(300);
const ret_plan = () => {
  router.go(-1);
};
const fileList = ref([]);
const quxiao = () => {
  show.value = false;
  fileList.value = [];
};
const admition = ref(JSON.parse(sessionStorage.getItem('admit_info')));
let show = ref(false);
// 下载图片
const success3 = lang.v.success3;
const error2 = lang.v.error2;
const downloadImage = imageUrl => {
  fetch(imageUrl)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = getFileNameFromUrl(imageUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success(success3);
    })
    .catch(() => {
      message.warning(error2);
    });
};
const getFileNameFromUrl = url => {
  const index = url.lastIndexOf('/') + 1;
  return url.substr(index);
};

// 点击图片放大
const isshow = ref(false);
const currentIndex = ref(0);
const currentImages = ref([]);
const onChange = index => {
  currentIndex.value = index;
};
const toggleZoom = (imageList, imgIndex) => {
  currentImages.value = imageList.map(imgs => 'https://oss.mnckj.com/' + imgs);
  currentIndex.value = imgIndex;
  isshow.value = true;
};
// 收放任务
const visibility = ref([]);
const Scale = index => {
  const isVisible = visibility.value.includes(index);
  if (isVisible) {
    visibility.value.splice(visibility.value.indexOf(index), 1);
    lists.value[index].isVisible = true;
  } else {
    visibility.value.push(index);
    lists.value[index].isVisible = false;
  }
};
const isVisible = index => {
  return visibility.value.includes(index);
};

// 图片的旋转
const rotations = ref([]);
const isRotated = index => {
  return rotations.value.includes(index);
};
const toggleRotation = index => {
  const isRotated = rotations.value.includes(index);
  if (isRotated) {
    rotations.value = rotations.value.filter(i => i !== index);
  } else {
    rotations.value.push(index);
  }
};

// 复制功能
const success4 = computed(() => lang.v.success4);
const copyText = (index, index_sucai) => {
  const textToCopy = lists.value[index].sourceContent[index_sucai];
  const clipboard = new Clipboard('.copy_button', {
    text: () => textToCopy,
  });
  // 监听复制成功事件
  clipboard.on('success', e => {
    message.success(success4);
    e.clearSelection();
  });

  // 监听复制失败事件
  clipboard.on('error', e => {
    message.error('err', e);
  });
};
const tasks = async e => {
  if (e.taskStatus == 4) {
    await act_task({
      id: e.taskReleaseId,
    }).then(async () => {
      e.taskStatus = 2;
      await task().then(res => {
        lists.value = res;
        lists.value = lists.value.filter(list => list.taskId !== 1);
      });
      return;
    });
  } else if (e.taskStatus == 2 || e.taskStatus == 1) {
    sessionStorage.removeItem('admit_info');
    sessionStorage.setItem('admit_info', JSON.stringify(e));
    if (sessionStorage.getItem('admit_info')) {
      admition.value = JSON.parse(sessionStorage.getItem('admit_info'));
      show.value = true;
      fileList.value = [];
    } else {
      return;
    }
  }
  if (e.taskStatus == 3 || e.taskStatus == 5) {
    return;
  }
};

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1; // 注意月份从0开始，所以需要加1
const day = currentDate.getDate();

const formattedDate = `${year}-${month}-${day}`;
const imageUrls = ref([]);
const file_list = ref([]);
const fileUploadQueue = ref([]);
const url = window.ocr;
const data = {
  base64: '',
};
const handleDeleteImage = () => {
  joinedText.value = ['file' + ' , ' + getCurrentDate()];
  tag.value = false;
  fileList.value = [];
};

const handleFileUpload = async files => {
  let isResponseReceived = false;

  const uploadImage = async file => {
    try {
      file.status = 'uploading';
      file.message = lang.v.uploading;
      fileUploadQueue.value.push(file);
      file_list.value = [file.file];
      const base64Content = file.content.split(',')[1];
      data.base64 = base64Content;

      const requestPromise = fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      });

      const timeoutPromise = new Promise(resolve => {
        setTimeout(() => {
          if (!isResponseReceived) {
            file.status = 'success';
            file.message = lang.v.success5;
            tag.value = true;
            resolve();
          }
        }, 15000); // 设置超时时间为 15 秒
      });

      await Promise.race([requestPromise, timeoutPromise]);

      fileUploadQueue.value = fileUploadQueue.value.filter(
        item => item !== file
      );

      const response = await requestPromise;
      const responseData = await response.json();

      isResponseReceived = true;

      if (response.ok && responseData?.code !== 200) {
        const data2 = responseData.data;
        console.log(responseData);
        for (const item of data2) {
          const text = item.text;
          textArray.push(text);
        }
        const joinedText2 = textArray.join(' ');
        joinedText.value = [joinedText2];
        file.status = 'success';
        file.message = lang.v.success5;
        tag.value = true;
        console.log(joinedText.value);
      } else {
        file.status = 'success';
        file.message = lang.v.success5;
        tag.value = true;
        console.log(joinedText.value);
      }
    } catch (error) {
      console.error(error);
      file.status = 'success';
      file.message = lang.v.success5;
      tag.value = true;
    }
  };

  if (!Array.isArray(files)) {
    const file = files;
    await uploadImage(file);
  } else {
    file_list.value = files;
    for (const file of files) {
      await uploadImage(file);
    }
    if (file_list.value.length > 0) {
      const base64Content = files[0].content.split(',')[1];
      data.base64 = base64Content;
    }
    console.log(joinedText.value);
  }
};
const show_moceng = ref(false);
const textArray = [];
function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
const joinedText = ref(['file' + ' , ' + getCurrentDate()]);
const tag = ref(false);
const admit_task = async file => {
  show_moceng.value = true;
  if (file_list.value.length === 0) {
    message.warning(lang.v.warning4);
    show_moceng.value = false;
    return;
  }
  const timeout = 120000; // 超时时间，单位为毫秒

  // 创建一个超时的 Promise
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Timeout')); // 超时时拒绝 Promise
    }, timeout);
  });
  try {
    await Promise.race([
      timeoutPromise,
      (async () => {
        for (const file_obj of file_list.value) {
          let uploadUrl = '';
          await file_sts().then(res => {
            uploadUrl = res.uploadUrl;
            file_s.value = res;
            console.log(file_s.value);
          });
          // 在这里处理上传完成后的逻辑
          let client = new OSS({
            secure: true,
            region: 'oss-accelerate',
            accessKeyId: file_s.value.accessKeyId, // OSS帐号
            accessKeySecret: file_s.value.accessKeySecret, // OSS 密码
            stsToken: file_s.value.stsToken,
            bucket: file_s.value.bucket, // 阿里云上存储的 Bucket
          });
          var f = file_obj;
          const Name = f.name;
          const suffix = Name.substr(Name.lastIndexOf('.'));
          const storeAs = file_s.value.key + suffix; // 路径+时间戳+后缀名
          try {
            const result = await client.multipartUpload(storeAs, f);
            console.log(result);
            let aliUrl = uploadUrl + result.name;
            // const aliUrlArray = Array.isArray(aliUrl) ? aliUrl : [aliUrl];
            imageUrls.value.push(aliUrl.toString());
            console.log(imageUrls.value); // 这就是阿里云返回给你的URL
          } catch (error) {
            console.log(error);
            show_moceng.value = false;
            show.value = false;
            file.status = 'failed';
            file.message = lang.v.uploadLimitOne;
          }
        }
      })(),
    ]);
    await admit_tasks({
      id: admition.value.taskReleaseId,
      imageUrls: imageUrls.value,
      contents: joinedText.value,
    }).then(() => {
      message.success(lang.v.success5);
    });
    show_moceng.value = false;
    show.value = false;
    fileList.value = [];
    await task().then(res => {
      lists.value = res;
      lists.value = lists.value.filter(list => list.taskId !== 1);
      console.log(lists.value);
    });
  } catch (error) {
    if (error.message === 'Timeout') {
      message.error(lang.v.timeout);
    } else {
      message.error(lang.v.success5);
    }
  } finally {
    show_moceng.value = false;
    show.value = false;
  }
};

// 储存授权的数组
const file_s = ref([]);
const lists = ref([]);
const userinfo = ref(JSON.parse(localStorage.getItem('userInfo')));
const filteredItems = ref();
onMounted(async () => {
  await task().then(res => {
    console.log(res, 444);

    lists.value = res;
    filteredItems.value = lists.value.filter(list => list.taskId === 1);
    lists.value = lists.value.filter(list => list.taskId !== 1);
    lists.value.forEach(item => {
      item.isVisible = false;
    });
  });
});
</script>
<style lang="less" scoped>
.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  overflow-y: scroll;
}

.slide-enter-active,
.slide-leave-active {
  transition: height 0.5s;
}

.hidden {
  display: none;
}

.slide-enter,
.slide-leave-to {
  height: 0;
}

.nav {
  width: 100%;
  height: 0.2rem;
  display: flex;
  margin-top: 0.16rem;
  justify-content: space-between;

  .nav_left {
    display: flex;
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 0.2rem;
    margin-left: 0.14rem;

    .left_jian {
      width: 0.2rem;
      height: 0.2rem;

      img {
        width: 100%;
      }
    }

    .word {
      background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .num_task {
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #858c9f;
    line-height: 0.2rem;
    margin-right: 0.14rem;
  }
}

.tasks {
  width: 92.53%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 0.08rem;
  margin-top: 0.16rem;

  .tasks_top1 {
    width: 100%;
    height: 0.12rem;
    background: url(~@/assets/img/become_top2.png) no-repeat;
    background-size: 100% 100%;
  }

  .tasks_top2 {
    width: 100%;
    height: 0.12rem;
    background-color: #5eb15c;
    border-radius: 0.06rem 0.06rem 0rem 0rem;
  }

  .tasks_top3 {
    width: 100%;
    height: 0.12rem;
    background: #0066ff;
    border-radius: 0.06rem 0.06rem 0rem 0rem;
  }

  .tasks_top4 {
    width: 100%;
    height: 0.12rem;
    background: #c2c5cf;
    border-radius: 0.06rem 0.06rem 0rem 0rem;
  }

  .tasks_top5 {
    width: 100%;
    height: 0.12rem;
    background: #dd4747;
    border-radius: 0.06rem 0.06rem 0rem 0rem;
  }

  .tasks_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.02rem;
    padding-bottom: 0.12rem;
    border-bottom: 1px solid rgba(133, 140, 159, 0.2);

    .tasks_left {
      display: flex;
      margin-left: 0.12rem;
      margin-top: 0.07rem;

      .task_logo1 {
        width: 0.2rem;
        height: 0.2rem;
        margin-top: 0.1rem;
        background: url(~@/assets/img/task.png);
        background-size: 100% 100%;
      }

      .task_logo2 {
        width: 0.2rem;
        height: 0.2rem;
        margin-top: 0.1rem;
        background: url(~@/assets/img/task_act.png);
        background-size: 100% 100%;
      }

      .task_logo3 {
        width: 0.2rem;
        height: 0.2rem;
        margin-top: 0.1rem;
        background: url(~@/assets/img/tasks_ing.png);
      }

      .task_logo4 {
        width: 0.2rem;
        height: 0.2rem;
        margin-top: 0.1rem;
        background: url(~@/assets/img/task_finish.png);
        background-size: 100% 100%;
      }

      .task_logo5 {
        width: 0.2rem;
        height: 0.2rem;
        margin-top: 0.1rem;
        background: url(~@/assets/img/task_false.png);
        background-size: 100% 100%;
      }

      .tasks_name1 {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ed931e;
        line-height: 0.2rem;
        margin-left: 0.12rem;
      }

      .tasks_name2 {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #5eb15c;
        line-height: 0.2rem;
        margin-left: 0.12rem;
      }

      .tasks_name3 {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #0066ff;
        line-height: 0.2rem;
        margin-left: 0.12rem;
      }

      .tasks_name4 {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #c2c5cf;
        line-height: 0.2rem;
        margin-left: 0.12rem;
      }

      .tasks_name5 {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #dd4747;
        line-height: 0.2rem;
        margin-left: 0.12rem;
      }

      .tasks_time {
        // width: 1.40rem;
        height: 0.2rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.2rem;
        margin-left: 0.12rem;
      }

      .tasks_time1 {
        // width: 1.40rem;
        height: 0.2rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #c2c5cf;
        line-height: 0.2rem;
        margin-left: 0.06rem;
      }
    }

    .tasks_right {
      display: flex;
      margin-right: 0.12rem;

      .receive1 {
        width: 0.9rem;
        height: 0.34rem;
        background: #ed931e;
        border-radius: 0.38rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.34rem;
        text-align: center;
        margin-right: 0.06rem;
        margin-top: 0.1rem;
      }

      .receive2 {
        width: 0.9rem;
        height: 0.34rem;
        background: rgba(94, 177, 92, 1);
        border-radius: 0.38rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.34rem;
        text-align: center;
        margin-right: 0.06rem;
        margin-top: 0.1rem;
      }

      .receive3 {
        width: 0.9rem;
        height: 0.34rem;
        background: rgba(0, 102, 255, 1);
        border-radius: 0.38rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-top: 0.1rem;
        line-height: 0.34rem;
        text-align: center;
        margin-right: 0.06rem;
      }

      .receive4 {
        width: 0.9rem;
        height: 0.34rem;
        background: rgba(194, 197, 207, 1);
        border-radius: 0.38rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.34rem;
        text-align: center;
        margin-top: 0.1rem;
        margin-right: 0.06rem;
      }

      .receive5 {
        width: 0.9rem;
        height: 0.34rem;
        background: #dd4747;
        border-radius: 0.38rem;
        opacity: 1;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.34rem;
        text-align: center;
        margin-top: 0.1rem;
        margin-right: 0.06rem;
      }
    }
  }

  .tishi_info {
    display: flex;
    width: 93.08%;
    margin: auto;
    margin-top: 0.14rem;

    .tishi_logo {
      width: 0.2rem;
      height: 0.2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tishi_text {
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #858c9f;
      line-height: 0.2rem;
      margin-left: 0.06rem;
    }
  }

  .contant {
    width: 93.08%;
    margin: auto;
    margin-top: 0.12rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 0.2rem;
    overflow: hidden;

    .exambal {
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #858c9f;
      line-height: 0.2rem;
      margin-top: 0.14rem;
      display: flex;
    }

    .copyimg {
      width: 0.24rem;
      height: 0.24rem;
      margin-left: 0.06rem;

      img {
        width: 100%;
      }
    }

    .exam_img {
      width: 29.5%;
      height: 1rem;
      margin-top: 0.14rem;
      margin-left: 0.12rem;
      position: relative;
      overflow: hidden;
      background-color: #111111;
      border-radius: 0.06rem;

      .image-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        // margin: 0.1rem;
        border-radius: 0.06rem;

        .image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
          border-radius: 0.06rem;

          img {
            width: 100%;
            border-radius: 0.06rem;
          }
        }
      }

      // line-height: 1.00rem;

      .box {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 0.65rem;
        height: 0.22rem;
        bottom: 0.08rem;
        background: rgba(68, 68, 68, 0.4);
        border-radius: 0.38rem;
        left: 50%;
        transform: translateX(-50%);

        .load_img {
          width: 0.14rem;
          height: 0.14rem;
          margin-top: 0.05rem;
          background: url(~@/assets/img/down_load.png) no-repeat;
          background-size: 100% 100%;
          margin-left: 0.12rem;
        }

        .down_load {
          height: 0.22rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.22rem;
          margin-right: 0.12rem;
        }
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }

  .contant1 {
    width: 93.08%;
    margin: auto;
    margin-top: 0.12rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 0.2rem;
    overflow: hidden;

    .exambal {
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #858c9f;
      line-height: 0.2rem;
      margin-top: 0.14rem;
      display: flex;
    }

    .copyimg {
      width: 0.24rem;
      height: 0.24rem;
      margin-left: 0.06rem;

      img {
        width: 100%;
      }
    }

    .exam_img {
      width: 1rem;
      height: 1rem;
      margin-top: 0.14rem;
      margin-left: 0.12rem;
      position: relative;
      overflow: hidden;
      background-color: #111111;

      // line-height: 1.00rem;
      .image-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        // margin: 0.1rem;
        border-radius: 0.06rem;

        .image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
          border-radius: 0.06rem;

          img {
            width: 100%;
            border-radius: 0.06rem;
          }
        }
      }

      .box {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 0.65rem;
        height: 0.22rem;
        bottom: 0.08rem;
        background: rgba(68, 68, 68, 0.4);
        border-radius: 0.38rem;
        left: 50%;
        transform: translateX(-50%);

        .load_img {
          width: 0.14rem;
          height: 0.14rem;
          margin-top: 0.05rem;
          background: url(~@/assets/img/down_load.png) no-repeat;
          background-size: 100% 100%;
          margin-left: 0.12rem;
        }

        .down_load {
          height: 0.22rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.22rem;
          margin-right: 0.12rem;
        }
      }
    }

    :deep(.van-image-preview__swipe) {
      .van-swipe__track {
        transition-duration: 500ms !important;
      }
    }
  }

  .wenzi {
    height: 0.3rem;
    font-size: 0.13rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #858c9f;
    line-height: 0.3rem;
  }

  .scale {
    width: 0.2rem;
    height: 0.2rem;
    margin-top: 0.05rem;
    cursor: pointer;

    img {
      cursor: pointer;
      transition: transform 0.5s;
    }

    .rotated {
      transform: rotate(180deg);
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 92.53%;
  background-color: #fff;
  border-radius: 0.06rem;
  padding-bottom: 0.14rem;

  .block_top {
    display: flex;
    justify-content: space-between;
    padding-top: 0.14rem;

    .block_top_left {
      display: flex;
      height: 0.4rem;
      line-height: 0.4rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #444444;
      margin-left: 0.14rem;
      margin-top: 0.12rem;

      .bolck_name {
        height: 0.17rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #5eb15c;
        line-height: 0.17rem;
      }

      .over_time {
        height: 0.17rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        margin-top: 0.01rem;
        color: #858c9f;
        line-height: 0.17rem;
        margin-left: 0.06rem;
      }
    }

    .block_top_right {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.04rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .main_info {
    width: 92.5%;
    margin: auto;
    margin-top: 0.14rem;

    p {
      margin: 0;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #858c9f;
      line-height: 0.2rem;
    }
  }

  .block_main {
    display: flex;
    margin: auto;
    margin-top: 0.06rem;

    .block_box {
      margin-top: 0.08rem;
      margin-left: 0.08rem;
      width: 1rem;
      height: 1rem;
    }
  }

  .block_foot {
    display: flex;
    justify-content: space-between;
    margin-top: 0.12rem;

    .block_foot_left {
      margin-left: 0.14rem;

      .bolck_name {
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #858c9f;
        line-height: 0.17rem;
      }

      .over_time {
        height: 0.17rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.17rem;
        margin-top: 0.02rem;
      }
    }

    .admit {
      width: 0.96rem;
      height: 0.34rem;
      background: #5eb15c;
      border-radius: 0.61rem;
      opacity: 1;
      text-align: center;
      line-height: 0.34rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-right: 0.14rem;
    }
  }
}
</style>
